import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const FAQs = ({ className, heading, uniqueFaqs }) => {
  let faqs = "";

  uniqueFaqs
    ? (faqs = uniqueFaqs)
    : (faqs = [
        {
          question: "",
          answer: <></>,
        },
      ]);

  return (
    <div className="container px-0">
      <section
        className={`-mx-4 mb-20 bg-primary-50 py-20 px-4 md:m-0 md:mb-32 md:rounded-4xl md:py-26 md:px-16 ${className}`}
      >
        <header className="mb-10 md:mb-16">
          <h2>{heading || "FAQs"}</h2>
        </header>

        <Accordion allowZeroExpanded={true} className="space-y-4 md:space-y-6">
          {faqs.map((faq, i) => {
            return (
              <div key={i}>
                <AccordionItem
                  className="rounded-xl bg-white py-4 px-4 md:py-5 md:px-6"
                  uuid={i}
                >
                  <AccordionItemButton className="flex justify-between space-x-6 focus:outline-none md:space-x-10 lg:space-x-18">
                    <AccordionItemState>
                      {(state) => {
                        return (
                          <>
                            <p className="mb-0 font-bold text-primary-900 md:text-lg">
                              {faq.question}
                            </p>

                            <div className="flex h-8 min-w-[32px] items-center justify-center rounded-full bg-primary-500 md:h-10 md:min-w-[40px]">
                              <i
                                className={`far ${
                                  state.expanded ? "fa-minus" : "fa-plus"
                                } text-xl text-white md:text-2xl`}
                              ></i>
                            </div>
                          </>
                        );
                      }}
                    </AccordionItemState>
                  </AccordionItemButton>
                  <AccordionItemPanel className="animate-fadeIn pt-6 pr-10">
                    {faq.answer}
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
        </Accordion>
      </section>
    </div>
  );
};

export default FAQs;
