import React from "react";
import MicroModal from "micromodal";
import ButtonWithIcon from "../Button/ButtonWithIcon";
import styled from "@emotion/styled";

const StyledModal = styled.div`
  .modal {
    &[aria-hidden="false"] {
      .overlay {
        animation: mmfadeIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="false"] {
      .content-wrapper {
        animation: mmpopUp 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .overlay {
        animation: mmfadeOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .content-wrapper {
        animation: mmpopOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
`;


    const Modal = () => {
      if (typeof window !== "undefined") {
        MicroModal.init({
          openTrigger: "data-modal-open",
          closeTrigger: "data-modal-close",
          disableFocus: true,
          disableScroll: true,
          awaitOpenAnimation: true,
          awaitCloseAnimation: true,
        });
      }

  return (
    <div>
      <StyledModal>
        <div
          className="modal relative z-50 hidden"
          id="modal-pricing"
          aria-hidden="false"
        >
          <div
            className="overlay fixed top-0 right-0 left-0 bottom-0 flex md:items-center justify-center bg-black bg-opacity-75 outline-none"
            tabIndex="-1"
            data-modal-close
          >
            <div
              className="content-wrapper overflow-auto md:overflow-hidden h-full md:h-[600px] w-[80%] px-4 pt-24 pb-40 md:py-20 md:px-6"
              role="dialog"
              aria-modal="true"
            >
             <div className=" px-[40px] pt-[39px] bg-[#323e46] rounded-xl pb-[80px]">
          <p className="text-white text-[20px] font-semibold text-left mb-[24px]">Services Provided in Your Financial Plan</p>
            <div className="md:columns-2 md:px-[40px]">
            
              <ul className="text-left text-[#FBFAF7] list-disc text-[14px] md:text-[16px] leading-8">
                <li className="leading-[16px] md:leading-8 pb-2">Financial Goal Development & Probability Analysis</li>
                <li className="leading-[16px] md:leading-8 pb-2">Financial Organization / Balance Sheet Preparation</li>
                <li className="leading-[16px] md:leading-8 pb-2">Cash Flow Planning and Budgeting / Debt Management</li>
                <li className="leading-[16px] md:leading-8 pb-2">Investment Management Review & Recommendations</li>
                <li className="leading-[16px] md:leading-8 pb-2">Insurance Evaluation & Recommendations</li>
                <li className="leading-[16px] md:leading-8 pb-2">Risk Tolerance Determination</li>
                <li className="leading-[16px] md:leading-8 pb-2">College Funding Plan</li>
                <li className="leading-[16px] md:leading-8 pb-2">Estate Planning Roadmap</li>
                <li className="leading-[16px] md:break-after-column md:leading-8 pb-2">Advanced Tax Planning Strategies</li>
                <li className="leading-[16px] md:leading-8 pb-2">Small-Business Retirement Planning / Qual. Plan Design & Management</li>
                <li className="leading-[16px] md:leading-8 pb-2">Stock Options & Equity Compensation Planning</li>
                <li className="leading-[16px] md:leading-8 pb-2">Real Estate Analysis & Recommendations</li>
                <li className="leading-[16px] md:leading-8 pb-2">Alternative Investment Opportunities</li>
                <li className="leading-[16px] md:leading-8 pb-2">Retirement Distribution Strategy</li>
                <li className="leading-[16px] md:leading-8 pb-2">Social Security Timing and Evaluation</li>
                <li className="leading-[16px] md:leading-8 pb-2">Roth Conversion Assessment</li>
              </ul>
            </div>
            {/* <div className="absolute bottom-[25px] left-16 z-100">
            <ButtonWithIcon
                      data-modal-close
                      className="text-[#ffffff] text-left"
                      icon="fa-solid fa-arrow-up"
                      text="Close"
                      altStyle={2}
                      
                    /> 
                    </div> */}
            </div>
              <i
                className="fas fa-times absolute top-16 right-6 mr-8 mt-8 cursor-pointer text-3xl text-white transition-colors duration-300 ease-linear hover:text-primary-800"
                data-modal-close
              ></i>
            </div>
          </div>
        </div>
      </StyledModal>
    </div>
  );
};

export default Modal;
