import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Switch } from "@headlessui/react";
import { Tooltip } from 'react-tooltip';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";
import styled from "@emotion/styled";
import { mapEdgesToNodes } from "../lib/helpers";
import { toPlainText } from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA2";
import FAQs from "../components/Repeating/FAQs";
import "jquery/dist/jquery.min.js";
import $ from 'jquery';
import ReactBootstrapSlider from "react-bootstrap-slider";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import PricingNewsletter from "../components/Form/PricingNewsletter";
import ModalPricing from "../components/Modal/ModalPricing";
import tw from "twin.macro";
const StyledContent = styled.div`

`;
const StyledSlider = styled.div`
  .slider {
    ${tw`w-full! h-auto! mb-14! mt-0.5`}
  }
  .slider-track {
    ${tw`bg-[#CEE2E6] rounded-lg bg-none! top-0! m-0! h-2!`}
  }
  .slider-tick-container {
    ${tw`top-0.5!`}
  }
  .slider-tick {
    ${tw`w-1! h-1! bg-white/70! shadow-none! opacity-100! bg-none! border-none! m-0!`}
    &:first-child {
      ${tw`hidden`}
    }
    &:last-child {
      ${tw`hidden`}
    }
  }
  .slider-tick-label-container {
    ${tw`m-0! relative top-6`}
  }
  .slider-tick-label {
    ${tw`text-xs! text-white font-medium! w-auto! -ml-1.5!`}
    &:first-child {
      ${tw`left-[2%]!`}
    }
    &:last-child {
      ${tw`hidden`}
    }
  }
  .slider-handle {
    ${tw`w-6! h-6! shadow-6xl! bg-[#CEE2E6]! bg-none! -top-2! z-10! flex justify-between items-center p-1`}
    &.hide {
      ${tw`hidden`}
    }
  }
  .tick-slider-selection {
    ${tw`bg-none! bg-transparent! shadow-none!`}
  }
  .tooltip-main {
    ${tw`absolute mt-10`}
    .tooltip-inner {
      ${tw`relative left-0! -ml-6! bg-white! shadow-4xl! rounded-sm! text-primary-900 px-2.5! py-1!`}
      font-size: 11px !important;
      line-height: 15px !important;
      &:after {
        content: "";
        ${tw`absolute -bottom-1.5 inset-x-0 mx-auto h-0 w-0 border-8 border-b-0 border-transparent border-t-white`}
      }
    }
  }
`;

const Page = ({ data }) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [navDropdownOpen1, setnavDropdownOpen1] = useState(false);
  const navClickHandler1 = () => {
    setnavDropdownOpen1(!navDropdownOpen1);
  };
  const [navDropdownOpen2, setnavDropdownOpen2] = useState(false);
  const navClickHandler2 = () => {
    setnavDropdownOpen2(!navDropdownOpen2);
  };
  const [navDropdownOpen3, setnavDropdownOpen3] = useState(false);
  const navClickHandler3 = () => {
    setnavDropdownOpen3(!navDropdownOpen3);
  };
  // const [switchActive, switchActive] = useState(false);
  // const navClickHandler3 = () => {
  //     setnavDropdownOpen3(!switchActive);
  // };
  const tier = {
    starter: [
      {
        name: "Starter",
      },
    ],
    grow: [
      {
        name: "Grow",
      },
    ],
    platform: [
      {
        name: "Platform (Unlimited)",
      },
    ],
  };
  const { props, errors } = data;
  const page = props && props.page;


  const truncate = (str) => {
    return str.length > 300 ? str.substring(0, 300) : str;
  };

  const subServiceNodes =
    data && data.subServices && mapEdgesToNodes(data.subServices);
  const [tabIndex, setTabIndex] = useState(0);

  const faqs = [
    {
      question:
        "What is an AUM (Assets Under Management) fee schedule?",
      answer: (
        <>
          <p className="mb-0">
            AUM Fee Schedule<br /><br />
            <ul className="pl-[15px]">
              <li><span className="font-semibold">First $1M</span> - 1.00%</li>
              <li><span className="font-semibold">$1M - $3M</span>  - 0.80%</li>
              <li><span className="font-semibold">$3M - $5M</span>  - 0.60%</li>
              <li><span className="font-semibold">Above $5M</span>  - 0.50%</li>
            </ul><br />
            We utilize Charles Schwab as the custodian of our client assets. The fee schedule is blended, and assets are billed quarterly in advance. For example, if a client has $2M in assets under our management, their annual fee would be 0.90% (First million at 1.0% and the second million at 0.8%). The quarterly fee would be 0.225%, and the fees would be deducted directly from the account.
                  </p>
        </>
      ),
    },
    {
      question:
        "What is counted in the AUM (Assets Under Management)?",
      answer: (
        <>
          <p className="mb-0">
            All assets that we actively manage on behalf of our clients are counted. This may include various financial instruments such as equities, ETFs and index funds, mutual funds, cash, and other securities.
                  </p>
        </>
      ),
    },
    {
      question:
        "Can I go through the Financial Planning Process, and decide not to move forward?",
      answer: (
        <>
          <p className="mb-0">
            Absolutely. While we aim for a long-term relationship, we realize that it may not always be the right fit. After we deliver your financial plan, we’ll outline what an ongoing engagement would look like. If you choose to move forward with us, we’ll be thrilled. If not, we part as friends and remain a potential resource for you in the future.
                  </p>
        </>
      ),
    },
    // {
    //   question: "How do you determine what “Tier” I should be on?",
    //   answer: (
    //     <>
    //       <p className="mb-0">
    //         We determine the Tier of your financial plan based on the complexity of your financial situation and needs. It’s important to note that we’ll get to know you during two complementary sessions, introductory and discovery, before providing an engagement proposal. This proposal will include the scope of work and pricing which we’ll jointly agree upon before we begin your plan.
    //               </p>
    //     </>
    //   ),
    // },
    {
      question:
        "How frequently do we touch base if I choose to be an ongoing client?",
      answer: (
        <>
          <p className="mb-0">
          For all ongoing client relationships, emails/calls/check-ins are unlimited. Review meetings are available to you on a quarterly basis. We ask that you keep at least your annual meeting on the books, but all other meetings are optional. If you’re satisfied and don’t want to meet, just know we are here for you.
                  </p>
        </>
      ),
    },
    {
      question:
        "Can you guarantee returns?",
      answer: (
        <>
          <p className="mb-0">
            Outside of fixed income assets such as CDs, US Treasuries, and certain insurance products, there are no guarantees associated with investing. All investments carry some level of risk (even the previously mentioned assets may be principal-protected yet carry inflationary risk). As such, we conduct a deep dive with every client to determine how they want their money to behave from a risk perspective.
                  </p>
        </>
      ),
    },

  ];
  const [sliderValue, setSliderValue,] = useState(0);
  const changeSliderValue = (e) => {
    setSliderValue(e.target.value);
  };



  let sliderValueTotal = {};
  if (sliderValue >= 8000001) {
    sliderValueTotal = " ";
  } else if
    (sliderValue > 7500000 && sliderValue <= 8000000) {
    sliderValueTotal = "BASED ON A FEE OF .67% of AUM";
  } else if
    (sliderValue > 7000000 && sliderValue <= 7500000) {
    sliderValueTotal = "BASED ON A FEE OF .69% of AUM";
  } else if
    (sliderValue > 6500000 && sliderValue <= 7000000) {
    sliderValueTotal = "BASED ON A FEE OF .70% of AUM";
  } else if
    (sliderValue > 6000000 && sliderValue <= 6500000) {
    sliderValueTotal = "BASED ON A FEE OF .72% of AUM";
  } else if
    (sliderValue > 5500000 && sliderValue <= 6000000) {
    sliderValueTotal = "BASED ON A FEE OF .74% of AUM";
  } else if
    (sliderValue > 5000000 && sliderValue <= 5500000) {
    sliderValueTotal = "BASED ON A FEE OF .76% of AUM";
  } else if
    (sliderValue > 4780000 && sliderValue <= 5000000) {
    sliderValueTotal = "BASED ON A FEE OF .77% of AUM";
  } else if
    (sliderValue > 4500000 && sliderValue <= 4750000) {
    sliderValueTotal = "BASED ON A FEE OF .78% of AUM";
  } else if
    (sliderValue > 4250000 && sliderValue <= 4500000) {
    sliderValueTotal = "BASED ON A FEE OF .79% of AUM";
  } else if
    (sliderValue > 4000000 && sliderValue <= 4250000) {
    sliderValueTotal = "BASED ON A FEE OF .80% of AUM";
  } else if
    (sliderValue > 3750000 && sliderValue <= 4000000) {
    sliderValueTotal = "BASED ON A FEE OF .81% of AUM";
  } else if
    (sliderValue > 3500000 && sliderValue <= 3750000) {
    sliderValueTotal = "BASED ON A FEE OF .82% of AUM";
  } else if
    (sliderValue > 3250000 && sliderValue <= 3500000) {
    sliderValueTotal = "BASED ON A FEE OF .83% of AUM";
  } else if
    (sliderValue > 3000000 && sliderValue <= 3250000) {
    sliderValueTotal = "BASED ON A FEE OF .85% of AUM";  
  } else if
    (sliderValue > 2500000 && sliderValue <= 3000000) {
    sliderValueTotal = "BASED ON A FEE OF .87% of AUM";
  } else if
    (sliderValue > 2250000 && sliderValue <= 2500000) {
    sliderValueTotal = "BASED ON A FEE OF .88% of AUM";
  } else if
    (sliderValue > 2000000 && sliderValue <= 2250000) {
    sliderValueTotal = "BASED ON A FEE OF .89% of AUM";
  } else if
    (sliderValue > 1750000 && sliderValue <= 2000000) {
    sliderValueTotal = "BASED ON A FEE OF .90% of AUM";
  }  else if
    (sliderValue > 1500000 && sliderValue <= 1750000) {
    sliderValueTotal = "BASED ON A FEE OF .91% of AUM";
  }  else if
    (sliderValue > 1250000 && sliderValue <= 1500000) {
    sliderValueTotal = "BASED ON A FEE OF .93% of AUM";
  }  else if
    (sliderValue > 1000000 && sliderValue <= 1250000) {
    sliderValueTotal = "BASED ON A FEE OF .96% of AUM";
  } else if
    (sliderValue <= 1000000) {
    sliderValueTotal = "BASED ON A FEE OF 1.00% of AUM";
  }

  let perQrt = {};
  if (sliderValue >= 8000001) {
    perQrt = "";
  } else if
    (sliderValue <= 8000001) {
    perQrt = "/Quarter";
  }


  let stepValue = {};
  if (sliderValue >= 250000) {
    stepValue = "250000";
  } else if 
   (sliderValue < 250000) {
     stepValue = "25000";
   }
  

  let sliderValuePrice = {};
  if (sliderValue > 8000001) {
    sliderValuePrice = "Contact us for a custom quote";
} else if
(sliderValue > 7750000 && sliderValue <= 8000000) {
sliderValuePrice = "$13,625";
} else if
(sliderValue > 7500000 && sliderValue <= 7750000) {
sliderValuePrice = "$13,250";
} else if
(sliderValue > 7250000 && sliderValue <= 7500000) {
sliderValuePrice = "$12,875";
} else if
(sliderValue > 7000000 && sliderValue <= 7250000) {
sliderValuePrice = "$12,500";
} else if
(sliderValue > 6750000 && sliderValue <= 7000000) {
sliderValuePrice = "$12,125";
} else if
(sliderValue > 6500000 && sliderValue <= 6750000) {
sliderValuePrice = "$11,750";
} else if
(sliderValue > 6250000 && sliderValue <= 6500000) {
sliderValuePrice = "$11,375";
} else if
(sliderValue > 6000000 && sliderValue <= 6250000) {
sliderValuePrice = "$11,000";
} else if
(sliderValue > 5750000 && sliderValue <= 6000000) {
sliderValuePrice = "$10,625";
} else if
(sliderValue > 5250000 && sliderValue <= 5500000) {
sliderValuePrice = "$10,250";
} else if
(sliderValue > 5000000 && sliderValue <= 5250000) {
sliderValuePrice = "$9,875";
  } else if
    (sliderValue > 4750000 && sliderValue <= 5000000) {
    sliderValuePrice = "$9,500";
  } else if
  (sliderValue > 4500000 && sliderValue <= 4750000) {
  sliderValuePrice = "$9,125";
  } else if
  (sliderValue > 4250000 && sliderValue <= 4500000) {
  sliderValuePrice = "$8,750";
  } else if
  (sliderValue > 4000000 && sliderValue <= 4250000) {
  sliderValuePrice = "$8,375";
  } else if
  (sliderValue > 3750000 && sliderValue <= 4000000) {
  sliderValuePrice = "$8,000";
  } else if
  (sliderValue > 3500000 && sliderValue <= 3750000) {
  sliderValuePrice = "$7,625";
  } else if
  (sliderValue > 3250000 && sliderValue <= 3500000) {
  sliderValuePrice = "$7,250";
  } else if
  (sliderValue > 3000000 && sliderValue <= 3250000) {
  sliderValuePrice = "$6,875";
  } else if
  (sliderValue > 2750000 && sliderValue <= 3000000) {
  sliderValuePrice = "$6,500";
  } else if
  (sliderValue > 2500000 && sliderValue <= 2750000) {
  sliderValuePrice = "$6,000";
  } else if
  (sliderValue > 2250000 && sliderValue <= 2500000) {
  sliderValuePrice = "$5,500";
  } else if
  (sliderValue > 2000000 && sliderValue <= 2250000) {
  sliderValuePrice = "$5,000";
  } else if
  (sliderValue > 1750000 && sliderValue <= 2000000) {
  sliderValuePrice = "$4,500";
  } else if
  (sliderValue > 1500000 && sliderValue <= 1750000) {
  sliderValuePrice = "$4,000";
  } else if
  (sliderValue > 1250000 && sliderValue <= 1500000) {
  sliderValuePrice = "$3,500";
  } else if
    (sliderValue > 1000000 && sliderValue <= 1250000) {
    sliderValuePrice = "$3,000";
  } else if
    (sliderValue > 750000 && sliderValue <= 1000000) {
    sliderValuePrice = "$2,500";
  } else if
    (sliderValue > 500000 && sliderValue <= 750000) {
    sliderValuePrice = "$1,875";
  } else if
    (sliderValue > 250000 && sliderValue <= 500000) {
    sliderValuePrice = "$1,250";
  } else if
    (sliderValue > 225000 && sliderValue <= 250000) {
    sliderValuePrice = "$625";
  } else if
    (sliderValue > 200000 && sliderValue <= 225000) {
    sliderValuePrice = "$562.50";
  } else if
    (sliderValue > 175000 && sliderValue <= 200000) {
    sliderValuePrice = "$500";
  } else if
    (sliderValue > 150000 && sliderValue <= 175000) {
    sliderValuePrice = "$437.50";
  } else if
    (sliderValue > 125000 && sliderValue <= 150000) {
    sliderValuePrice = "$375";
  } else if
    (sliderValue > 100000 && sliderValue <= 125000) {
    sliderValuePrice = "$312.50";
  } else if
    (sliderValue > 75000 && sliderValue <= 100000) {
    sliderValuePrice = "$250";
  } else if
    (sliderValue > 50000 && sliderValue <= 75000) {
    sliderValuePrice = "$187.50";
  } else if
    (sliderValue > 25000 && sliderValue <= 50000) {
    sliderValuePrice = "$125";
  } else if
    (sliderValue > 1 && sliderValue <= 25000) {
    sliderValuePrice = "$62.50";
  } else if
    (sliderValue <= 0) {
    sliderValuePrice = "$0";
  }
  
  return (
    <Layout serviceType="wealth">
      <SearchEngineOptimization
        title="Our Pricing | Dark Horse Private Wealth"
        description="Choose a financial plan and pricing structure that fits your unique needs by partnering with Dark Horse CPAs. Call us today to invest in your future."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative overflow-hidden pt-20 pb-20 md:pt-32 md:pb-36 bg-[#1E2A33]">
        <div className="container mb-[150px]">

          <div className="md:mx-6 mx-2">
            <h1 className="text-white text-center text-[34px] md:text-[48px]">Transparent Pricing for All</h1>
            <p className="text-white text-center max-w-[680px] mx-auto">
              There are no minimums in terms of investable assets or net worth to be a Dark Horse Private Wealth client. And, the initial discovery call is complimentary. There is, however, a minimum fee for us to take you on as a client.
                </p>
          </div>
        </div>


        <div className='absolute md:invisible lg:visible lg:left-[50%] lg:top-[11%] -mx-[313px] z-1'>
          <GatsbyImage
            objectFit=''
            imgClassName=''
            className='w-[626px]'
            image={data.timelineObject.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="container bg-white md:rounded-xl mb-[120px] z-2 relative">
          <div className="mb-6 p-4 py-10 md:p-18 grid grid-cols-10 gap-4 ">

            <div className=" col-span-10 md:col-span-1 border-b-4 md:border-b-0 md:border-r-4">
              <h4 className="text-center md:text-left md:-rotate-90 md:-mr-[50px] md:-ml-[145px] md:mt-[15px]">Zero.Dollars. </h4>
            </div>
            <div className="col-span-10 md:col-span-7 md:ml-[65px]">

              <h3 className="text-center md:text-left">Book a Complimentary 2-Part Discovery Call</h3>
              <ul className="ml-4 max-w-[600px]">
                <li className="mb-2"><span className="font-bold">1st Call:</span> Get acquainted & discover ways we can help.</li>
                <li className="mb-6 ml-8">- If we're a fit, we'll request some information & schedule a 2nd call.</li>
                <li className="mb-2"><span className="font-bold">2nd Call:</span> Review financial profile & discuss long-term goals.</li>
                <li className="mb-2 ml-8">- You decide whether you'd like to continue the process.</li>
              </ul>
            </div>
            <div className="col-span-10 md:col-span-2 md:mt-[40%]">
            <ButtonSolid
            href="/schedule-consult/"
            text="Get Started"
            altStyle={3}
            className="w-full md:w-auto text-center mx-auto px-[55px] py-[12px]"
          />
            </div>

          </div>
        </div>

        <div className="container bg-black/60 md:rounded-xl mb-24 md:border-[#CEE2E6] md:border-[1px] text-center p-4 py-16 md:p-[60px] z-2 relative md:mb-[140px]">
          
          <h3 className="text-white mb-2 px-4">We’ll Build Your Financial Plan</h3>
          <p className="text-[#f7f7f7] mx-auto mb-[20px]">Based on what we heard from you in our discovery calls, we'll build a financial plan that will be the map for your entire financial journey.</p>
          <p className="text-[14px] uppercase text-[#cee2e6] mt-[45px]">ONE-TIME FEE:</p>
          <p className="text-[40px] text-[#f7f7f7] font-['Playfair'] font-bold"><sup className="-top-[5px] text-[40px]">$</sup>3,500</p>
          <div className="min-h-[360px] md:min-h-[100px] my-[52px] px-[40px] py-[39px] bg-[#323e46] rounded-xl listCollapse listContain">
          <p className="hidden serviceTitle text-white text-[20px] font-semibold text-left mb-[24px]">Services Provided in Your Financial Plan</p>
            <div className="md:columns-2 px-4 md:px-[40px] mask-bottom maskedList">
            
              <ul className="text-left text-[#FBFAF7] list-disc text-[14px] md:text-[16px] md:leading-8">
                <li>Financial Goal Development & Probability Analysis</li>
                <li>Financial Organization / Balance Sheet Preparation</li>
                <li>Cash Flow Planning and Budgeting / Debt Management</li>
                <li>Investment Management Review & Recommendations</li>
                <li>Insurance Evaluation & Recommendations</li>
                <li>Risk Tolerance Determination</li>
                <li>College Funding Plan</li>
                <li>Estate Planning Roadmap</li>
                <li className="break-after-column">Advanced Tax Planning Strategies</li>
                <li>Small-Business Retirement Planning / Qual. Plan Design & Management</li>
                <li>Stock Options & Equity Compensation Planning</li>
                <li>Real Estate Analysis & Recommendations</li>
                <li>Alternative Investment Opportunities</li>
                <li>Retirement Distribution Strategy</li>
                <li>Social Security Timing and Evaluation</li>
                <li>Roth Conversion Assessment</li>
              </ul>
            </div>
            <ModalPricing />
            <div className="absolute bottom-[200px] left-14 md:left-28 z-100 visible showList">
          <ButtonWithIcon
                      modal="modal-pricing"
                      className="text-[#ffffff]"
                      icon="fa-solid fa-arrow-down"
                      text="Show All Services"
                      altStyle={2}
                    /> 
                    </div>
          
          <div className="absolute bottom-[200px] left-28 z-100 hidden closeList">
          <ButtonWithIcon
                      className="text-[#ffffff] showAllBtn"
                      icon="fa-solid fa-arrow-down"
                      text="Close List"
                      altStyle={2}
                    /> 
                    </div>
          </div>
          <ButtonSolid
            href="/schedule-consult/"
            text="Get Started"
            altStyle={3}
            className="w-full md:w-auto text-center mx-auto px-[55px] py-[12px]"
          />
        
        </div>

        <div className="container bg-secondary-100 md:rounded-xl mb-24  text-center px-0 md:px-[1rem] pb-[45px] md:p-[45px] z-2 relative">
          <div className="py-[45px] px-8">
          <h3 className="">Your Plan In Action</h3>
          <p className=" max-w-[784px] mx-auto ">Now that you're equipped with your Financial Plan, it's time to put it in motion. We'll guide you at each step of the journey, ensuring the Plan becomes reality and adapts as circumstances change.</p>
          </div>
            
            <div className="text-center md:rounded-xl bg-[#323e46] md:my-[56px] md:mx-[80px] px-10 md:px-[40px] py-[45px] md:text-left">
             
              <p className="text-center mb-2 text-white font-bold text-[18px] leading-6">We Price to be Aligned, Not to Take Advantage</p>
              <p className="text-center mx-auto text-[#F7F5EF] max-w-[652px] mb-[60px]">Our fees as a % of assets decrease as the assets increase because the work required doesn't increase 1:1 with each dollar added.</p>
              <p className="text-[30px] text-[#ffffff] text-center font-['Playfair'] font-bold mb-2">{sliderValuePrice}<span className="font-normal text-[26px]">{perQrt}</span></p>
              <p className="text-[14px] uppercase text-[#cee2e6] text-center">{sliderValueTotal}</p>
              <p className="text-[#86ACB2] text-left max-w-[340px] mt-[20px] mb-[50px] text-[14px]">Investable assets</p>
              <StyledSlider>
                <ReactBootstrapSlider
                  value={sliderValue}
                  step={stepValue}
                  change={changeSliderValue}
                  orientation="horizontal"
                  ticks={[
                    0, 250000, 1000000, 3000000, 5000000, 10000000, 15000000,
                  ]}
                  ticks_labels={[
                    "$0",
                    "$250K",
                    "$1M",
                    "$3M",
                    "$5M",
                    "$10M+",
                    "",]}
                  ticks_positions={[
                    0, 16.67, 33.33, 50, 66.66, 83.33, 100,
                  ]}
                  formatter={function (value) {
                    return "$" + value.toLocaleString("en-US");
                  }}
                />
              </StyledSlider>
           
            </div>

         <div className="w-[90%] mx-auto mt-12 md:mt-0">
            <ButtonSolid
            href="/schedule-consult/"
            text="Get Started"
            altStyle={3}
            className="w-full md:w-auto text-center mx-auto px-[55px] py-[12px]"
          />
          </div>
        </div>
        <div className="container md:rounded-xl border-dashed border border-[#485E70] mb-24  text-center py-[60px] md:p-[60px] z-2 max-w-[785px] mx-auto">
        <h3 className="text-white mb-[32px]">Not ready to become a client just yet?</h3>
          <p className="text-[#F7F5EF] text-[16px] leading-[24px] mx-auto mb-[20px]">If you're interested in working with Dark Horse Private Wealth in the future but not quite ready to make moves in the here-and-now, <span className="font-bold">sign up for our mailing list.</span> </p>
          <p className="text-[#F7F5EF] text-[16px] leading-[24px] mx-auto mb-[42px]">Don't worry…we won't spam you! Periodically, we'll send you materials about investment and tax saving opportunities to keep us top of mind and help you identify when it's go-time.</p>
          <div className="max-w-[499px] mx-auto">
          <PricingNewsletter />
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div class="container md:-mt-[120px] z-9 relative md:px-0">

          <FAQs uniqueFaqs={faqs} />
        </div>
      </section>
      {/* break */}

      <section className="bg-secondary-100 mb-0 zigZagBg">
        <div class="container py-[50px]">

          <CallToAction rotatingWords="Taxes" />
        </div>
      </section>
    </Layout>
  );
};

export const data = graphql`
  {
    
    serviceHero: file(relativePath: { eq: "philosophy/matt-card.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    blockImage: file(relativePath: { eq: "homepage/Image.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    homepageCoinStack: file(relativePath: { eq: "homepage/3-coinstack.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    homepageVenDiagram: file(relativePath: { eq: "homepage/2-intro.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    grayCrosses: file(relativePath: { eq: "locations/gray-crosses.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    homepageHeroCoins: file(
      relativePath: { eq: "homepage/hero-illustration-grouped.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 530)
      }
    }
    homepageHeroDesktop: file(
      relativePath: { eq: "locations/gray-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    timelineObject: file(relativePath: { eq: "pricing/dotted line with nmbers - desktop .png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    subServices: allSanitySubService(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    sectionDivider: file(
      relativePath: { eq: "philosophy/section-divider.svg" }
    ) {
      publicURL
    }
    horseHeadBlue: file(relativePath: { eq: "global/logo-head-blue.svg" }) {
      publicURL
    }
    philosophyHeroUnderlay: file(
      relativePath: { eq: "philosophy/2-line background.svg" }
    ) {
      publicURL
    }
    hero: file(relativePath: { eq: "foreign Tax/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 624)
      }
    }
    taxesCanBeStressful: file(
      relativePath: { eq: "Personal Taxes/taxes-can-be-stressful.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    team: file(relativePath: { eq: "Personal Taxes/team.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    compOut: file(relativePath: { eq: "foreign Tax/outbound.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    compIn: file(relativePath: { eq: "foreign Tax/inbound.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    taxes: file(relativePath: { eq: "Personal Taxes/taxes.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    orangeSquare: file(
      relativePath: { eq: "global/decorative-blue-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    wheel: file(
      relativePath: { eq: "Process/wheel.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 44)
      }
    }
    
    compass: file(
      relativePath: { eq: "Process/compass.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 44)
      }
    }

    homepageDictionary: file(
      relativePath: { eq: "homepage/4-dictionary.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 493)
      }
    }

    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    optimizeYourPipeline: file(
      relativePath: { eq: "3.0 Features/2. Optimize Your Pipeline.svg" }
    ) {
      publicURL
    }
    createEfficiency: file(
      relativePath: { eq: "3.0 Features/4.0 Create Efficiency.svg" }
    ) {
      publicURL
    }
    integrationAutomationReports: file(
      relativePath: {
        eq: "3.0 Features/5.0 Integration _ Automation Reports.svg"
      }
    ) {
      publicURL
    }
    success1: file(
      relativePath: { eq: "3.0 Features/3.0 Free Strategy Call.svg" }
    ) {
      publicURL
    }
    success2: file(
      relativePath: { eq: "3.0 Features/3.1 Dedicated Support Agent.svg" }
    ) {
      publicURL
    }
    success3: file(
      relativePath: { eq: "3.0 Features/3.2 Easy Ways To Connect With Us.svg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
